import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MainPage from '../mainpage/MainPage';
import { getProfiles } from '../../actions/profile';
import { getEvents } from '../../actions/event';
import BirthdayItem from './BirthdayItem';
import EventItem from './EventItem';
import Spinner from '../../components/shared/Spinner';
import formatDate from '../../utils/formatDate';
import MembershipCategory from '../shared/enums/MembeshipCategory';
import MemberItem from './MemberItem';

import './Home.css';

const Home = ({
  getProfiles,
  profile: { profiles, loading },
  getEvents,
  event: { events },
}) => {
  const [todayEvents, setTodayEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [todaysBirthdays, setTodaysBirthdays] = useState([]);
  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);
  const [founderMembers, setFounderMembers] = useState([]);
  const [coFounderMembers, setCoFounderMembers] = useState([]);
  const [executiveMembers, setExecutiveMembers] = useState([]);
  const [advisoryMembers, setAdvisoryMembers] = useState([]);

  useEffect(() => {
    getProfiles();
    getEvents();
  }, [getProfiles, getEvents]);

  useEffect(() => {
    if (profiles && events) {
      const today = new Date();
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      );

      const getEventsAndMembersAndBirthdays = (events, profiles) => {
        // console.log('Events', events);
        const todaysEvents = [];
        const upcomingEvents = [];
        const todaysBirthdays = [];
        const upcomingBirthdays = [];
        const founderMembers = [];
        const coFounderMembers = [];
        const executiveMembers = [];
        const advisoryMembers = [];

        events.forEach((event) => {
          // console.log('event', event);
          const eventStartDate = new Date(formatDate(event.start));
          const eventEndDate = new Date(formatDate(event.end));
          const eventStartDateCentral = new Date(
            eventStartDate.toLocaleString('en-US', {
              timeZone: 'America/Chicago',
            })
          );
          const eventEndDateCentral = new Date(
            eventEndDate.toLocaleString('en-US', {
              timeZone: 'America/Chicago',
            })
          );

          if (eventStartDateCentral.toDateString() === today.toDateString()) {
            todaysEvents.push(event);
          } else if (
            eventStartDateCentral > today &&
            eventEndDateCentral <= lastDayOfMonth
          ) {
            upcomingEvents.push(event);
          }
        });

        profiles.forEach((profile) => {
          if (!profile.user) return; // Skip if profile.user is null
          const dob = new Date(formatDate(profile.dob));
          const dobCentral = new Date(
            dob.toLocaleString('en-US', { timeZone: 'America/Chicago' })
          );
          const dobThisYear = new Date(
            today.getFullYear(),
            dobCentral.getMonth(),
            dobCentral.getDate()
          );

          if (dobThisYear.toDateString() === today.toDateString()) {
            todaysBirthdays.push(profile);
          } else if (dobThisYear > today && dobThisYear <= lastDayOfMonth) {
            upcomingBirthdays.push(profile);
          }

          profile.membership.forEach((member) => {
            if (member) {
              const memberData = {
                _id: profile._id,
                user: profile.user,
                membership: member,
              };

              switch (member.category) {
                case MembershipCategory.FOUNDER:
                  founderMembers.push(memberData);
                  break;
                case MembershipCategory.COFOUNDER:
                  coFounderMembers.push(memberData);
                  break;
                case MembershipCategory.EXECUTIVE:
                  if (member.current) {
                    executiveMembers.push(memberData);
                  }
                  break;
                case MembershipCategory.ADVISORY:
                  if (member.current) {
                    advisoryMembers.push(memberData);
                  }
                  break;
                default:
                  break;
              }
            }
          });
        });

        return {
          todaysEvents,
          upcomingEvents,
          todaysBirthdays,
          upcomingBirthdays,
          founderMembers,
          coFounderMembers,
          executiveMembers,
          advisoryMembers,
        };
      };

      const {
        todaysEvents,
        upcomingEvents,
        todaysBirthdays,
        upcomingBirthdays,
        founderMembers,
        coFounderMembers,
        executiveMembers,
        advisoryMembers,
      } = getEventsAndMembersAndBirthdays(events, profiles);

      setTodayEvents(todaysEvents);
      setUpcomingEvents(upcomingEvents);
      setTodaysBirthdays(todaysBirthdays);
      setUpcomingBirthdays(upcomingBirthdays);
      setFounderMembers(founderMembers);
      setCoFounderMembers(coFounderMembers);
      setExecutiveMembers(executiveMembers);
      setAdvisoryMembers(advisoryMembers);
    }
  }, [profiles, events]);

  return (
    <MainPage>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className='wrapper'>
            <div className='event'>
              <div>
                <h4 className='text-primary py'>Today's Events</h4>
                <div className='todays-events'>
                  {todayEvents.length > 0 ? (
                    todayEvents.map((event) => (
                      <EventItem key={event._id} event={event} />
                    ))
                  ) : (
                    <h4 className='text-danger py'>
                      No events found for today...
                    </h4>
                  )}
                </div>
              </div>
              <div>
                <h4 className='text-primary py'>Upcoming Events</h4>
                <div className='upcoming-events'>
                  {upcomingEvents.length > 0 ? (
                    upcomingEvents.map((event) => (
                      <EventItem key={event._id} event={event} />
                    ))
                  ) : (
                    <h4 className='text-danger py'>
                      No upcoming events found...
                    </h4>
                  )}
                </div>
              </div>
            </div>
            <div className='birthday'>
              <div>
                <h4 className='text-primary py'>Today's Birthday(s)</h4>
                <div className='todays-birthdays'>
                  {todaysBirthdays.length > 0 ? (
                    todaysBirthdays.map((profile) => (
                      <BirthdayItem key={profile._id} profile={profile} />
                    ))
                  ) : (
                    <h4 className='text-danger py'>
                      No today's birthday's found...
                    </h4>
                  )}
                </div>
              </div>
              <div>
                <h4 className='text-primary py'>Upcoming Birthday(s)</h4>
                <div className='upcoming-birthdays'>
                  {upcomingBirthdays.length > 0 ? (
                    upcomingBirthdays.map((profile) => (
                      <BirthdayItem key={profile._id} profile={profile} />
                    ))
                  ) : (
                    <h4 className='text-danger py'>
                      No upcoming birthday's found...
                    </h4>
                  )}
                </div>
              </div>
            </div>
            <div className='founder'>
              <div>
                <h4 className='text-primary py'>Founder members</h4>
                <div className='founder-members'>
                  {founderMembers.length > 0 ? (
                    founderMembers.map((member) => (
                      <MemberItem key={member._id} profile={member} />
                    ))
                  ) : (
                    <h4 className='text-danger py'>
                      No Founder members found...
                    </h4>
                  )}
                </div>
              </div>
            </div>
            <div className='cofounder'>
              <div>
                <h4 className='text-primary py'>Co-Founder members</h4>
                <div className='coFounder-members'>
                  {coFounderMembers.length > 0 ? (
                    coFounderMembers.map((member) => (
                      <MemberItem key={member._id} profile={member} />
                    ))
                  ) : (
                    <h4 className='text-danger py'>
                      No Co-Founder members found...
                    </h4>
                  )}
                </div>
              </div>
            </div>
            <div className='excutive'>
              <div>
                <h4 className='text-primary py'> Executive members</h4>
                <div className='executive-members'>
                  {executiveMembers.length > 0 ? (
                    executiveMembers.map((member) => (
                      <MemberItem key={member._id} profile={member} />
                    ))
                  ) : (
                    <h4 className='text-danger py'>
                      No Executive members found...
                    </h4>
                  )}
                </div>
              </div>
            </div>
            <div className='advisory'>
              <div>
                <h4 className='text-primary py'> Advisory members</h4>
                <div className='advisory-members'>
                  {advisoryMembers.length > 0 ? (
                    advisoryMembers.map((member) => (
                      <MemberItem key={member._id} profile={member} />
                    ))
                  ) : (
                    <h4 className='text-danger py'>
                      No Advisory members found...
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </MainPage>
  );
};

Home.propTypes = {
  getProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  getEvents: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  event: state.event,
});

export default connect(mapStateToProps, { getProfiles, getEvents })(Home);
