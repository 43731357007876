import React from 'react';
import PropTypes from 'prop-types';
import formatDate from '../../utils/formatDate';
import extractTime from '../../utils/extractTimeAndYear';

const EventItem = ({ event }) => {
  if (!event) {
    return null;
  }

  const { title, start, end, notes } = event;

  const startDate = typeof start === 'string' ? new Date(start) : start;
  const endDate = typeof end === 'string' ? new Date(end) : end;

  const { time: startTime } = extractTime(startDate.toISOString());
  const { time: endTime } = extractTime(endDate.toISOString());

  return (
    <div className='event-item bg-light'>
      <div>
        <h6>{title}</h6>
      </div>

      <div>
        <h6>{formatDate(start)}</h6>
        <h6>
          {startTime} - {endTime}
        </h6>
      </div>
      <div>
        <h6>{notes}</h6>
      </div>
    </div>
  );
};

EventItem.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    notes: PropTypes.string,
  }).isRequired,
};

export default EventItem;
