// function extractTime(dateString) {
//   if (!dateString || typeof dateString !== 'string') {
//     return 'Invalid Date';
//   }

//   const date = new Date(dateString);

//   // Check if date is valid
//   if (isNaN(date.getTime())) {
//     return 'Invalid Date';
//   }

//   const hours = date.getHours();
//   const minutes = date.getMinutes().toString().padStart(2, '0');

//   // Determine AM/PM
//   const ampm = hours >= 12 ? 'PM' : 'AM';
//   const formattedHours = hours % 12 || 12; // The hour '0' should be '12'
//   const hoursStr = formattedHours.toString().padStart(2, '0');

//   return `${hoursStr}:${minutes} ${ampm}`;
// }

// export default extractTime;

function extractTimeAndYear(dateString) {
  if (!dateString || typeof dateString !== 'string') {
    return { time: 'Invalid Date', year: 'Invalid Date' };
  }

  const date = new Date(dateString);

  // Check if date is valid
  if (isNaN(date.getTime())) {
    return { time: 'Invalid Date', year: 'Invalid Date' };
  }

  // Extracting time
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // Determine AM/PM
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // The hour '0' should be '12'
  const hoursStr = formattedHours.toString().padStart(2, '0');
  const time = `${hoursStr}:${minutes} ${ampm}`;

  // Extracting year
  const year = date.getFullYear();

  return { time, year };
}

export default extractTimeAndYear;
